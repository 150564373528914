<template>
  <div class="view-link-card">
    <div class="custom-columns">
      <div class="custom-column left-column">

      </div>

      <div class="custom-column right-column">
        <div class="right-column-inner">
            <div class="right-column-inner-top">
              <h2>
                {{ $translations.getTranslation('link_card_form_title') }}
              </h2>

              
              <p>
                {{ $translations.getTranslation('link_card_form_text1') }}
              </p>
              <p>
                {{ $translations.getTranslation('link_card_form_text2') }} <a target="_blank" :href="$translations.getTranslation('link_card_form_text2_href')">{{ $translations.getTranslation('link_card_form_text2_after') }}</a>. <b-icon icon="box-arrow-up-right" aria-hidden="true" role="presentation"></b-icon>
              </p>
            </div>

            <form @submit.prevent>
              <div class="row-spacing">
                <input-floating-label
                        id="member-number"
                        :labelName="$translations.getTranslation('link_card_member_number_label')"
                        :disabled="true"
                        :requiredDisclaimer="true"
                        :value="$user.getMemberNumber()">

                </input-floating-label>
              </div>

              <div class="row-spacing">
                <input-floating-label
                        id="card-number"
                        :labelName="$translations.getTranslation('link_card_credit_card_number_label')"
                        :mask="cardNumberInputLimitations.mask"
                        :max-length="cardNumberInputLimitations.maxLength"
                        :errorMessage="getCardNumberErrorMsg()"
                        autocomplete="cc-number"
                        @onInput="onInputCardNumber">

                  <img slot="image" src="@/assets/images/schemes/visa-vop.png" v-if="activeScheme.visa" alt="Icon">
                  <img slot="image" src="@/assets/images/schemes/mc-cls.png" v-if="activeScheme.mastercard" alt="Icon">
                  <img slot="image" src="@/assets/images/schemes/nets-storebox.png" v-if="activeScheme.visaDankort" alt="Icon">
                </input-floating-label>
              </div>

              <transition name="fade">
                <div class="row-spacing">
                  <b-row>
                    <b-col sm="6">
                      <input-floating-label
                              id="expiry-date"
                              :labelName="$translations.getTranslation('link_card_expiry_date_label')"
                              mask="##/##"
                              max-length="5"
                              placeholder="mm/yy"
                              autocomplete="cc-exp"
                              :errorMessage="getExpiryDateErrorMsg()"
                              @onInput="onInputExpiryDate">

                      </input-floating-label>
                    </b-col>
                  </b-row>
                </div>
              </transition>

              <div class="right-column-inner-middle">
                <div class="row-spacing">
                  <p>
                    {{ $translations.getTranslation('link_card_terms_and_conditions_label1') }}
                    <a href="#" v-on:click.prevent="termAndConditionsHandler" role="button">
                      {{ $translations.getTranslation('link_card_terms_and_conditions_link1_label') }}
                    </a>
                  </p>

                  <consents @onConsent="onConsent"></consents>

                  <p>
                    {{ $translations.getTranslation('link_card_terms_and_conditions_label3') }}
                    <a :href="getRoutePath('ManageCards')" @click.prevent="goToRoute('ManageCards')">
                      {{ $translations.getTranslation('link_card_terms_and_conditions_label3_link') }}
                    </a>
                  </p>
                </div>
              </div>

              <div class="row-spacing">
                <button-primary :disabled="!isFormValid" @click.native="triggerCaptcha" :spinner="isFormLoading">
                  {{ $translations.getTranslation('link_card_form_button') }}
                </button-primary>
              </div>
            </form>

            <div class="row-spacing">
              <schemes/>
            </div>

            <div class="row-spacing">
              <div class="logo-wrapper">
                <loyal-solutions-logo/>
              </div>
            </div>
        </div>
      </div>
    </div>

    <invisible-captcha
      @OnVerify="onCaptchaVerifyEvent"
      @OnCancel="onCaptchaCancelEvent"
      @OnError="onCaptchaErrorEvent"
      @OnExpired="onCaptchaExpiredEvent">
    </invisible-captcha>

    <modal-no-scheme/>
    <modal-visa-scheme/>
    <modal-mastercard-scheme/>
    <modal-visa-dankort-scheme/>
    <modal-lopi-notice/>
    <modal-lopi-mastercard-notice/>
    <modal-login :verifyToken="verifyToken"/>
  </div>
</template>

<script>
  // Components
  import Consents from '@/views/linkCard/components/Consents';
  import Schemes from '@/views/linkCard/components/Schemes';

  // UI
  import ButtonPrimary from '@/components/ui/button/Primary';
  import LoyalSolutionsLogo from '@/components/ui/lsLogo/Logo';
  import InvisibleCaptcha from '@/components/ui/captcha/InvisibleCaptcha';
  import InputFloatingLabel from '@/components/ui/input/FloatingLabel';

  // Models
  import CreditCardsDefault from '@/models/creditCardsDefault';
  import VisaCardNumber from '@/models/visaCardNumber';
  import MastercardCardNumber from '@/models/mastercardCardNumber';
  import DankortCardNumber from '@/models/dankortCardNumber';
  import VisaDankortCardNumber from '@/models/visaDankortCardNumber';
  import LinkCardApi from '@/models/linkCardApi';
  import ProfileApi from "@/models/profileApi";
  import BlockedBinNumbers from '@/models/blockedBinNumbers';
  import PermittedBinNumbers from '@/models/permittedBinNumbers';

  // Modals
  import ModalNoScheme from '@/views/linkCard/components/ModalNoScheme';
  import ModalVisaScheme from '@/views/linkCard/components/ModalVisaScheme';
  import ModalMastercardScheme from '@/views/linkCard/components/ModalMastercardScheme';
  import ModalVisaDankortScheme from '@/views/linkCard/components/ModalVisaDankortScheme';
  import ModalLopiNotice from '@/components/ui/modal/ModalLopiNotice';
  import ModalLopiMastercardNotice from '@/components/ui/modal/ModalLopiMastercardNotice';
  import ModalLogin from '@/components/ui/modal/ModalLogin';

  // Configs
  import AdditionalFormConsentConfig from '@/configs/additionalFormConsentConfig';
  import CardSchemesConfig from '@/configs/cardSchemesConfig';


  // Mixins
  import {mixinRoutesHandler} from '@/mixins/routesHandler';

  export default {
    name: 'ViewLinkCard',

    props: {
      autoLogin: {
        default: false,
        type: Boolean
      },
      code: {
        default: null,
        type: String
      },
      state: {
        default: null,
        type: String
      },
      nonce: {
        default: null,
        type: String
      },
      showKillAuthError: {
        default: false,
        type: Boolean
      }
    },

    mixins: [mixinRoutesHandler],

    components: {
      ModalLopiNotice, ModalLopiMastercardNotice,
      InputFloatingLabel, Consents, ButtonPrimary, InvisibleCaptcha, ModalNoScheme, ModalVisaScheme,
      ModalMastercardScheme, ModalVisaDankortScheme, Schemes, LoyalSolutionsLogo, ModalLogin
    },

    data: function () {
      return {
        agreedToLopiMastercardNotice: false,
        agreedToLopiNotice: false,
        justLinkedPureDankort: false,
        cardNumberInputLimitations: {
          mask: '',
          maxLength: ''
        },

        tooltipErrorMessages: {
          expiryDate: null,
          cardNumber: null,
        },

        activeScheme: {
          visa: false,
          visaDankort: false,
          pureDankort: false,
          mastercard: false,
        },

        userInput: {
          cardNumber: null,
          expiryDate: null,
        },

        formDataState: {
          isConsent: false,
          isValidCard: false,
          isValidExpiryDate: false
        },

        verifyToken: {
          code: null,
          state: null,
          nonce: null
        },

        captcha: {
          token: null,
        },

        isFormValid: false,
        isFormLoading: false,
      }
    },

    methods: {
      getRoutePath(routeName) {
        return this._mixinGetRoutePath(routeName);
      },

      goToRoute(routeName) {
        this._mixinGoToRouteName(routeName);
      },

      startFormLoader() {
        this.isFormLoading = true;
      },

      stopFormLoader() {
        this.isFormLoading = false;
      },

      onCaptchaVerifyEvent(token) {
        this.captcha.token = token;
        this.linkCard();
      },

      onCaptchaCancelEvent() {
        this.stopFormLoader();
      },

      onCaptchaExpiredEvent() {
        this.stopFormLoader();
        this.$root.$emit('ls::global-alert::error',
            this.$translations.getTranslation(
                'link_card_global_alert_captcha_expired'
            )
        );
      },

      onCaptchaErrorEvent() {
        this.stopFormLoader();
        this.$root.$emit('ls::global-alert::error',
            this.$translations.getTranslation(
                'link_card_global_alert_captcha_error'
            )
        );
      },

      clearForm() {
        this.$root.$emit('ls::input-floating-label::reset-value', 'card-number');
        this.$root.$emit('ls::input-floating-label::reset-value', 'expiry-date');
        this.$root.$emit('ls::checkbox::reset', 'consent-standard');
        this.$root.$emit('ls::checkbox::reset', 'consent-personal');
      },

      onConsent(state) {
        this.formDataState.isConsent = state;
      },

      onInputExpiryDate(obj) {
        // Set user input card number
        this.setUserInputExpiryDate(obj.value);

        // Expiry date val
        this.cardExpiryDateHandler();
      },

      onInputCardNumber(obj) {
        // Set user input card number
        this.setUserInputCardNumber(obj.value);

        // Card scheme detector
        this.cardSchemeHandler();
      },

      triggerCaptcha() {
        if (this.isFormValid) {
          this.startFormLoader();
          this.$emit('triggerCaptcha');
        }
      },

      getExpiryMonth() {
        let date = this.userInput.expiryDate;
        date = date.split('/');

        return date[0];
      },

      getExpiryYear() {
        let date = this.userInput.expiryDate;
        date = date.split('/');

        return date[1];
      },

      linkCard() {
       let _this = this;

        const data = {
          params: {
            'g-recaptcha-response': this.captcha.token,
            cardNumber: this.userInput.cardNumber,
            month: this.getExpiryMonth(),
            year: this.getExpiryYear(),
          },
        };

        new LinkCardApi(data, {}).post(function (response) {
          if (!response.status || response.status === 401 || response.status === 403) {
            _this.clearForm();
            _this.stopFormLoader();
            _this.$root.killAuth();
            return;
          }

          if (response.status !== 200) {
            _this.stopFormLoader();

            _this.$root.$emit('ls::global-alert::error', _this.$translations.getTranslation(
                response.data.errors ? response.data.errors : 'link_card_global_alert_generic_error_message'
            ));

            return;
          }

          // Card is linked clear form
          _this.justLinkedPureDankort = _this.activeScheme.pureDankort;
          _this.clearForm();

          new ProfileApi({}, {}).get(function (response) {
            _this.stopFormLoader();

            if (!response.status || response.status === 401 || response.status === 403) {
              _this.$root.killAuth();
              return;
            }

            if (response.status !== 200) {
              _this.$root.$emit('ls::global-alert::error', _this.$translations.getTranslation(
                  'link_card_global_alert_card_linked_but_could_not_load_profile_info_error_message'
              ));

              return;
            }

            // Set user info
            _this.$user.setName(response.data.first_name);
            _this.$user.setEverLinkedCard(response.data.everLinkedPaymentCard);

            // get user info
            _this.$user.getUser();
            _this.$router.push({
              name: 'ManageCards',
              params: {
                triggerModalCardAdded: true,
                justLinkedPureDankort: _this.justLinkedPureDankort
              }
            });
          });
        });
      },

      cardExpiryDateHandler() {
        this.setIsExpiryDateValidState(false);

        this.resetExpiryDateTooltipErrorMsg();

        let expiryDate = this.userInput.expiryDate;

        if (!expiryDate || expiryDate.length !== 5) {
          return;
        }

        expiryDate = this.$moment(expiryDate, "MM/YY");
        let today  = this.$moment();

        if (expiryDate.isAfter(today) || expiryDate.isSame(today, 'month')) {
          this.setIsExpiryDateValidState(true);
          return;
        }

        this.setTooltipErrorMsg('expiryDate', this.$translations.getTranslation('link_card_expiry_date_error_message'));
      },

      cardSchemeHandler() {
        // Reset card number input limitations
        this.resetCardNumberInputLimitations();

        // Reset card scheme icons
        this.resetActiveScheme();

        // Reset is valid card form state
        this.resetIsCardValidFormDataState();

        // Reset tooltip error
        this.resetTooltipErrorCardNumberErrorMsg();

        // Get user input card number
        let cardNumber = this.userInput.cardNumber;

        // Reset additional consents
        this.$root.$emit('ls::consent-checkbox::hide');

        // Card number length check
        if (!cardNumber || cardNumber.length <= 0) {
          return;
        }

        let BlockedBins = new BlockedBinNumbers(cardNumber);

        if (BlockedBins.isLopiBlockedBins()) {
          this.setTooltipErrorMsg('cardNumber', this.$translations.getTranslation('link_card_blocked_bin_lopi_error_message'));
          return;
        }
        
        let PermittedBins = new PermittedBinNumbers(cardNumber);
        
        if (PermittedBins.isPermittedLopiBins() && !this.agreedToLopiNotice) {
          this.agreedToLopiNotice = true;
          this.agreedToLopiMastercardNotice = true;
          this.$root.$emit('bv::show::modal', 'modal-lopi-notice');
        }

        let VISA       = new VisaCardNumber(cardNumber);
        let MASTERCARD = new MastercardCardNumber(cardNumber);
        let VISADANKORT= new VisaDankortCardNumber(cardNumber);
        let DANKORT    = new DankortCardNumber(cardNumber);

        // Additional Consents
        this.consentsHandler();

        // Checking start of cardnumber against the schemes needing validation later
        if (MASTERCARD.isStartOfScheme() || DANKORT.isStartOfScheme()) {
          if (cardNumber.length >= 2) {
            if (CardSchemesConfig.MASTERCARD_IS_AVAILABLE && MASTERCARD.isScheme()) {
              // Set card number input limitations
              let limitations = MASTERCARD.getInputLimitations();
              this.setCardNumberInputLimitations(limitations.maxLength, limitations.mask);

              // Set active scheme
              this.activeScheme.mastercard = true;

              let result = this.schemeCardNumberHandler(MASTERCARD, cardNumber);
              if (result && !this.agreedToLopiMastercardNotice) {
                this.agreedToLopiMastercardNotice = true;
                this.$root.$emit('bv::show::modal', 'modal-lopi-mastercard-notice');
              }
            } else if (CardSchemesConfig.DANKORT_IS_AVAILABLE && DANKORT.isScheme()) {
              // Set card number input limitations
              let limitations = DANKORT.getInputLimitations();
              this.setCardNumberInputLimitations(limitations.maxLength, limitations.mask);
              // Set active scheme
              this.activeScheme.visaDankort = true;
              this.activeScheme.pureDankort = true;

              this.schemeCardNumberHandler(DANKORT, cardNumber);
            } else {
              this.setTooltipErrorMsg('cardNumber', this.$translations.getTranslation('link_card_invalid_card_scheme_error_message'));
            }
          }
        } else if (CardSchemesConfig.VISA_IS_AVAILABLE && VISA.isScheme()) {
            // Set card number input limitations
            let limitations = VISA.getInputLimitations();
            this.setCardNumberInputLimitations(limitations.maxLength, limitations.mask);

            // If Visa Dankort
            if (VISADANKORT.isScheme()) {
              // Set active scheme
              this.activeScheme.visaDankort = true;
            } else {
              // Set active scheme
              this.activeScheme.visa = true;
            }

            this.schemeCardNumberHandler(VISA, cardNumber);
        } else {
          this.setTooltipErrorMsg('cardNumber', this.$translations.getTranslation('link_card_invalid_card_scheme_error_message'));
        }
      },

      consentsHandler() {
        const cardNumber = this.userInput.cardNumber;

        if (cardNumber && cardNumber.length >= 6) {
          const firstSixBins = parseInt(cardNumber.substring(0, 6));

          if (AdditionalFormConsentConfig.NORDEA.includes(firstSixBins)) {
            this.$root.$emit('ls::consent-checkbox::show', 'nordea');
          }

          if (AdditionalFormConsentConfig.SEB.includes(firstSixBins)) {
            this.$root.$emit('ls::consent-checkbox::show', 'seb');
          }
        }
      },

      termAndConditionsHandler() {
        /*if (this.activeScheme.visa) {
          this.$root.$emit('bv::show::modal', 'modal-visa-scheme');
          return;
        }

        if (this.activeScheme.visaDankort) {
          this.$root.$emit('bv::show::modal', 'modal-visa-dankort-scheme');
          return;
        }

        if (this.activeScheme.mastercard) {
          this.$root.$emit('bv::show::modal', 'modal-mastercard-scheme');
          return;
        }*/

        this.$root.$emit('bv::show::modal', 'modal-no-scheme');
      },

      formStateValidHandler() {
        this.isFormValid = (this.formDataState.isValidCard && this.formDataState.isValidExpiryDate && this.formDataState.isConsent);
      },

      schemeCardNumberHandler(scheme, cardNumber) {
        if (cardNumber.length === scheme.getMaxLength()) {
          if (scheme.IsValidCardNumber()) {
            this.setIsCardValidFormDataState(true);
            return true;
          }

          this.setTooltipErrorMsg('cardNumber', this.$translations.getTranslation('link_card_invalid_card_number_error_message'));
        }
      },

      loginHandler() {
        if (!this.code || !this.state) {
          this.$root.$emit('bv::show::modal', 'modal-login');
          return;
        }

        this.$root.$emit('bv::show::modal', 'modal-login');

        this.verifyToken.code  = this.code;
        this.verifyToken.state = this.state;
        this.verifyToken.nonce = this.nonce ? this.nonce : null;
      },

      getCardNumberErrorMsg() {
        return this.tooltipErrorMessages.cardNumber;
      },

      getExpiryDateErrorMsg() {
        return this.tooltipErrorMessages.expiryDate;
      },

      setUserInputExpiryDate(date) {
        this.userInput.expiryDate = date;
      },

      setUserInputCardNumber(cardNumber) {
        // Strip white spaces & set user input card number
        this.userInput.cardNumber = cardNumber ? cardNumber.replace(/\s/g, "") : cardNumber;
      },

      setCardNumberInputLimitations(maxLength, mask) {
        this.cardNumberInputLimitations.maxLength = maxLength;
        this.cardNumberInputLimitations.mask      = mask;
      },

      setIsCardValidFormDataState(state) {
        this.formDataState.isValidCard = state;
      },

      setTooltipErrorMsg(target, msg) {
        this.tooltipErrorMessages[target] = msg;
      },

      setIsExpiryDateValidState(state) {
        this.formDataState.isValidExpiryDate = state;
      },

      resetIsCardValidFormDataState() {
        this.setIsCardValidFormDataState(false);
      },

      resetCardNumberInputLimitations() {
        this.cardNumberInputLimitations.maxLength = new CreditCardsDefault('').getMaxLengthWithWhiteSpaces();
        this.cardNumberInputLimitations.mask      = new CreditCardsDefault('').getMask();
      },

      resetActiveScheme() {
        this.activeScheme.visa        = false;
        this.activeScheme.mastercard  = false;
        this.activeScheme.visaDankort = false;
        this.activeScheme.pureDankort = false;
      },

      resetTooltipErrorCardNumberErrorMsg() {
        this.tooltipErrorMessages.cardNumber = null;
      },

      resetExpiryDateTooltipErrorMsg() {
        this.tooltipErrorMessages.expiryDate = null;
      },

      checkAuthState() {
        if (!this.$user.isAuth()) {
          this.clearForm();
        }
      },
    },

    mounted: function () {
      this.$nextTick(function () {
        if (this.showKillAuthError) {
          this.$root.$emit('ls::global-alert::error', this.$translations.getTranslation('kill_auth_general_error_message'));
        }

        if (!this.$user.isAuth()) {
          this.loginHandler();

          if (this.autoLogin) {
            this.$root.$emit('ls::modal-login::auto-login');
          }
        }
      });
    },

    created() {
      this.resetCardNumberInputLimitations();
    },

    watch: {
      'formDataState': {
        handler() {
          this.formStateValidHandler();
        },
        deep: true
      },
      '$root.userClass': {
        handler() {
          this.checkAuthState();
        },
        deep: true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .view-link-card {
    width: 100%;

    .custom-columns {
      width: 100%;
    }

    .custom-column {
      width: 100%;
    }

    .left-column {
      height: 130px;
      background-size: cover;
      background-image: url('~@/assets/images/linkCard/banner_small.jpg');
      background-repeat: no-repeat;
    }

    .right-column {
      padding: 30px 16px;

      .right-column-inner {
        max-width: 500px;
        margin: auto;

        .right-column-inner-top {
          h2, p {
            text-align: center;
          }
        }

        .right-column-inner-middle {
          h2, p {
            text-align: left;
          }

          a {
            svg {
              font-size: 15px;
              vertical-align: -0.05em !important;
            }
          }
        }
      }

      .logo-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    @media only screen and (min-width: 500px) {
      .left-column {
        background-position: 0 50%;
      }
    }

    @media only screen and (min-width: 980px) {
      .custom-column {
        width: 50%;
        height: 100vh;
        display: table-cell;
      }

      .custom-columns {
        display: table;
      }

      .left-column {
        background-image: url('~@/assets/images/linkCard/banner.jpg');
        min-height: -webkit-calc(100vh - 72px);
        min-height: -moz-calc(100vh - 72px);
        min-height: calc(100vh - 72px);
      }

      .right-column {
        padding: 70px 16px 0 60px;

        .right-column-inner {
          padding: 20px;
          margin: 0;

          .right-column-inner-top {
            h2, p {
              text-align: left;
            }
          }
        }
      }
    }
  }
</style>
